<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>{{ titleText }}, {{ currentCondominium.name }}</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <apartment-form save-btn-text="Luo"></apartment-form>
  </div>
</template>

<script>
import ApartmentForm from "../../components/Apartment/ApartmentForm";
import { mapActions, mapState, mapMutations } from "vuex";
import mixins from "../../mixins/mixins";

export default {
  title: "Uusi vuokrakohde",

  mixins: [mixins],

  components: {
    ApartmentForm,
  },

  data() {
    return {};
  },

  computed: {
    ...mapState("condominium", ["currentCondominium"]),

    titleText() {
      if (this.$route.query.createCopy) {
        return "Kopioi vuokrakohde";
      } else {
        return "Uusi vuokrakohde";
      }
    },
  },

  async created() {
    this.setLoading(true);
    await this.getOneCondominium(this.$route.params.id);
    await this.getAllPartners("/api/v1/partner/getAll");
  },

  methods: {
    ...mapActions("condominium", ["getOneCondominium"]),
    ...mapActions("partner", ["getAllPartners"]),
    ...mapMutations("apartment", ["setLoading"]),
  },
};
</script>

<style scoped></style>
